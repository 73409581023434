import Trustpilot from '~/svg/Trustpilot.svg';


export default function TrustpilotReviewsLink() {
  return (<div className="flex items-end leading-4 font-secondary text-md font-light text-white/50">
                  See our reviews on{' '}
                  <a
                    href="https://www.trustpilot.com/review/sellpixels.gg"
                    target="_blank"
      rel="noopener noreferrer"
                    className="block ml-2"
                  >
                    <Trustpilot className="h-6" />
                  </a>
                </div>);  
}