import UnderlineLink from '@/components/ui/links/UnderlineLink';

import Container from '@/layout/Container';

import LogoFull from '~/svg/Logo-Full.svg';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className='flex w-full items-center justify-between border-t border-neutral-700 py-5'>
      <Container>
        <div className='flex flex-col items-center justify-between gap-4 xl:flex-row xl:gap-0'>
          <div className='flex flex-col items-center justify-start gap-4 xl:flex-row xl:gap-10'>
            <LogoFull className='w-52' />
            <div className='text-right text-sm font-normal leading-snug text-stone-300'>
              SellpixelsGG {currentYear} - ALL rights reserved
            </div>
          </div>

          <div className="flex gap-6">
            <UnderlineLink
              href='/'
              className='text-sm text-stone-300 hover:text-white'
            >
              Home
            </UnderlineLink>
            <UnderlineLink
              href='/'
              className='text-sm text-stone-300 hover:text-white'
            >
              About
            </UnderlineLink>
            <UnderlineLink
              href='mailto:support@sellpixels.gg'
              className='text-sm text-stone-300 hover:text-white'
            >
              Contact Us
            </UnderlineLink>
            <UnderlineLink
              href="/privacy"
              className='text-sm text-stone-300 hover:text-white'
            >
              Privacy Policy
            </UnderlineLink>
            <UnderlineLink
              href="/terms-conditions"
              className='text-sm text-stone-300 hover:text-white'
            >
              Terms of Use
            </UnderlineLink>
          </div>
        </div>
      </Container>
    </div>
  );
}
